:root {
  --yellow: #f7e700;
  --pink: #e40078;
  --blue: #164193;
  --dark: black;
  --light: white;
  --background: var(--yellow);
  --foreground: var(--dark);
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  padding: 0;
  margin: 0;
  transition: background-color 0.2s ease-in-out;
  background: var(--background);
  color: var(--foreground);
}

body {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: flex-start;
  justify-content: center;
  padding-top: 1rem;
  font-size: 1.1rem;
  line-height: 1.7rem;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

input[type="number"] {
  background: transparent;
  font-size: 1.1rem;
  border: none;
  border-bottom: 2px solid var(--foreground);
  appearance: textfield;
  line-height: 2.5rem;
  color: var(--foreground);
}

label {
  line-height: 2.5rem;
}

span {
  padding: 0 1ch 0 0;
  font-weight: bold;
}

h1 {
  text-align: center;
  font-size: 3rem;
  line-height: 3.2rem;
  margin: 1.5rem 0;
}

h2 {
  text-align: center;
  font-size: 1.4rem;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
}

#root,
.App,
section {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

section {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0;
}

footer {
  margin: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

table {
  margin-bottom: 2rem;
}

.info {
  display: flex;
  justify-content: space-between;
}

#exchange {
  margin-top: 2rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
}

.tooltip {
  position: relative; /* making the .tooltip span a container for the tooltip text */
}

.tooltip:hover:before {
  display: block;
}

.tooltip:before {
  content: attr(data-text); /* here's the magic */
  position: absolute;
  font-size: 0.8rem;
  line-height: 1rem;
  z-index: 1000;
  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* move to right */
  left: 60%;

  /* basic styles */
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;

  display: none; /* hide by default */
}

#stock {
  transition: background-color 0.2s ease-in-out;
  padding: 2rem;
  background: var(--background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#spark {
  width: 80vw;
}

.btn {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  outline: none;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 1rem;
  border: 3px solid var(--foreground);
}

.btn:hover,
.btn:focus,
.btn:active {
  box-shadow: -3px 3px var(--foreground);
}

.btn-link {
  display: block;
  color: var(--foreground);
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

#theme:hover,
theme:focus,
theme:active {
  border-radius: 0;
}

#theme {
  width: 2rem;
  height: 2rem;
  border: 2px solid var(--foreground);
  border-radius: 50%;
}

.secret {
  cursor: help;
}

figcaption {
  width: 100%;
  font-size: 1.1rem;
  text-align: center;
}

td {
  padding: 0 2ch;
}

th {
  text-align: center;
}

.title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

#stock-details {
  font-size: 1.1rem;
}

#stock-details ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#stock-details li {
  display: inline;
  margin: 0 1ch;
}

#news {
  font-size: 1rem;
  padding: 16px;
}

#news ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
}

#news li {
}

#news a {
  color: var(--foreground);
}

@media only screen and (min-width: 800px) {
  body {
    align-items: center;
    justify-content: center;
    padding-top: 0;
  }

  h1 {
    font-size: 4rem;
    line-height: 4rem;
  }

  h2 {
    font-size: 2.2rem;
  }

  div {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }

  .input-group {
    flex-direction: row;
    margin: 0;
  }

  input[type="number"] {
    margin-left: 2ch;
  }

  #spark {
    width: 464px;
  }

  .title-wrapper {
    margin: 0 0 3rem 0;
  }

  #stock-details {
    line-height: 2rem;
  }

  #stock-details ul {
    flex-direction: row;
  }

  #stock {
    padding: 0;
  }
}
